<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/home" class="back-button">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary"><h2 class="mb-0 ion-text-center">About</h2></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true" class="bg-white text-black">
      <div class="ion-padding horizontally-centered ion-margin-bottom wp-content">
        <p>
          Welcome to SleepStar, this app has been designed by University College London in
          collaboration with the UK charity Cerebra. SleepStar contains information on sleep for
          families of children with neurodevelopmental conditions.
        </p>
        <p>
          Disclaimer: The information is this app is not designed to replace medical advice from
          paediatricians, GPs or other Healthcare professionals.
        </p>
        <p>
          If you would like to contact the UCL team please email
          <a href="mailto:Sleepstar@ucl.ac.uk">Sleepstar@ucl.ac.uk</a>. The project is led by Dr Liz
          Halstead and the Sleep Education and Research Laboratory.
        </p>
        <p>
          For further advice on sleep or to ask about any of the sleep solutions, please contact a
          healthcare provider or Cerebra Sleep Service by emailing
          <a href="mailto:sleep@cerebra.org.uk">sleep@cerebra.org.uk</a>
        </p>
        <p><a href="#" target="_blank" rel="noreferrer noopener">Privacy policy</a></p>
        <p><a href="#" target="_blank" rel="noreferrer noopener">Terms and conditions</a></p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'Bye',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  },
  setup(): any {
    return {
      chevronBackOutline
    };
  }
};
</script>
