
import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'Bye',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  },
  setup(): any {
    return {
      chevronBackOutline
    };
  }
};
